import React from 'react';
import styles from './PodcastList.module.sass';
import PodcastTile from 'src/components/pages/podcasts/PodcastTile/PodcastTile';

const PodcastList = ({data}) => {
  return (
    <div className={styles.listWrap}>
      {data.map(podcast => (
        <PodcastTile key={podcast.id} podcast={podcast}/>
      ))}
    </div>
  );
};

export default PodcastList;